@charset "UTF-8";
/* * * * * * * * * *
+320px :: 20em
+480px :: 30em
+600px :: 37.50em
+768px :: 48em
+992px :: 62em
+1024px :: 64em
+1140px :: 71.250em
+1224px :: 76.5em
+1382px :: 86.375em
* * * * * * * * * */
/* COULEURS DES BOUTONS SELON LEUR ÉTAT */
/* Media Queries*/
/* Phones */
/* Tablets*/
/* Tablets Horizontal*/
/* Desktops, slightly less large devices */
/* Desktops, large devices */
/* Desktops, medium large devices */
/* Desktops, very large devices */
/* px and rem */
/* -----------------------------
   FUNCTIONS
   ----------------------------- */
/*
Removes the unit (e.g. px, em, rem) from a value, returning the number only.
@param {Number} $num - Number to strip unit from.
@returns {Number} The same number, sans unit.
*/
/*
Converts one or more pixel values into matching rem values.
@param {Number|List} $values - One or more values to convert. Be sure to separate them with spaces and not commas. If you need to convert a comma-separated list, wrap the list in parentheses.

@param {Number} $base [null] - The base value to use when calculating the `rem`. If you're using Foundation out of the box, this is 16px. If this parameter is `null`, the function will reference the `$base-font-size` variable as the base.

@returns {List} A list of converted values.
*/
/*
Converts a pixel value to matching rem value. *Any* value passed, regardless of unit, is assumed to be a pixel value. By default, the base pixel value used to calculate the rem value is taken from the `$global-font-size` variable.
@access private
@param {Number} $value - Pixel value to convert.
@param {Number} $base [null] - Base for pixel conversion.
@returns {Number} A number in rems, calculated based on the given value and the base pixel value. rem values are passed through as is.
*/
/********
*****************************************************************
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
* * IA Grid V0.1
* * Eric Fournier
* * eric.fournier@nurun.com
* * 11 Aout 2014
* * Industrielle Alliance
* * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * * *
*****************************************************************
********/
.grid {
  width: 100%;
  padding: 0 15px;
  margin: 0 auto;
  background: #fff;
}

.row {
  width: 100%;
  display: block;
  margin: 0 auto;
}

.row:before, .row:after {
  content: "";
  display: table;
}

.row:after {
  clear: both;
}

/*.row .row {
margin-right: -10px;
margin-left: -10px;

Ce style doit etre plus specifique, svp utiliser wrapper (FD)
}*/
.row .row.nomargin {
  margin-right: 0;
  margin-left: 0;
}

.row .row:before, .row .row:after {
  content: "";
  display: table;
}

.row .row:after {
  clear: both;
}

.row.padding-top {
  padding-top: 10px;
}

/* Equal height columns (must somehow somewhere specify a background on all columns)
DO NOT add anything else except columns in the row div
Tested and working in Chrome 39, IE 9 and Firefox 35 */
.row.equal-height {
  overflow: hidden;
  width: 100%;
  margin: 0 0 10px;
}

.row.equal-height [class*=col-] {
  margin-bottom: -99999px;
  padding-bottom: 99999px;
  /* The lines below are necessary because the padding won't be effective with the added backgrounds */
  background-clip: padding-box;
  padding-right: 0;
  border-right: 10px solid transparent;
}

.row.equal-height [class*=col-]:last-of-type {
  border-right: none;
}

@media only screen and (max-width: 48em) {
  .row.equal-height [class*=col-med-],
  .row.equal-height [class*=col-lg-] {
    border-right: 0;
  }
}
/* gutter */
[class*=col-] {
  float: left;
  width: 100%;
  min-height: 1px; /* Let's prevent empty columns from ever collapsing horizontally */
}

[class*=col-]:last-of-type {
  padding-right: 0;
}

/*Outside gutters*/
.gutter {
  padding: 20px 0 20px 20px;
}

.gutter > [class*=col-]:last-of-type {
  padding-right: 10px;
}

.col-1-2,
.col-2-2,
.col-1-3,
.col-2-3,
.col-3-3,
.col-1-4,
.col-2-4,
.col-3-4,
.col-4-4,
.col-1-5,
.col-2-5,
.col-3-5,
.col-4-5,
.col-5-5,
.col-1-6,
.col-2-6,
.col-3-6,
.col-4-6,
.col-5-6,
.col-6-6,
.col-1-7,
.col-2-7,
.col-3-7,
.col-4-7,
.col-5-7,
.col-6-7,
.col-7-7,
.col-1-8,
.col-2-8,
.col-3-8,
.col-4-8,
.col-5-8,
.col-6-8,
.col-7-8,
.col-8-8,
.col-1-9,
.col-2-9,
.col-3-9,
.col-4-9,
.col-5-9,
.col-6-9,
.col-7-9,
.col-8-9,
.col-9-9,
.col-1-10,
.col-2-10,
.col-3-10,
.col-4-10,
.col-5-10,
.col-6-10,
.col-7-10,
.col-8-10,
.col-9-10,
.col-10-10,
.col-1-11,
.col-2-11,
.col-3-11,
.col-4-11,
.col-5-11,
.col-6-11,
.col-7-11,
.col-8-11,
.col-9-11,
.col-10-11,
.col-11-11,
.col-1-12,
.col-2-12,
.col-3-12,
.col-4-12,
.col-5-12,
.col-6-12,
.col-7-12,
.col-8-12,
.col-9-12,
.col-10-12,
.col-11-12,
.col-12-12 {
  width: 100%;
}

/*500px*/
@media only screen and (min-width: 500px) {
  [class*=col-sm] {
    float: left;
    padding-right: 8px;
    padding-left: 8px;
  }
  [class*=col-sm]:first-of-type {
    padding-left: 0;
  }
  [class*=col-sm]:last-of-type {
    padding-right: 0;
  }
  .col-sm-1-2 {
    width: 50%;
  }
  .col-sm-2-2 {
    width: 100%;
  }
  /* 3 colonnes */
  .col-sm-1-3 {
    width: 33.33%;
  }
  .col-sm-2-3 {
    width: 66.66%;
  }
  .col-sm-3-3 {
    width: 100%;
  }
  /* 4 colonnes */
  .col-sm-1-4 {
    width: 25%;
  }
  .col-sm-2-4 {
    width: 50%;
  }
  .col-sm-3-4 {
    width: 75%;
  }
  .col-sm-4-4 {
    width: 100%;
  }
  /* 5 colonnes */
  .col-sm-1-5 {
    width: 20%;
  }
  .col-sm-2-5 {
    width: 40%;
  }
  .col-sm-3-5 {
    width: 60%;
  }
  .col-sm-4-5 {
    width: 80%;
  }
  .col-sm-5-5 {
    width: 100%;
  }
  /* 6 colonnes */
  .col-sm-1-6 {
    width: 16.66%;
  }
  .col-sm-2-6 {
    width: 33.33%;
  }
  .col-sm-3-6 {
    width: 50%;
  }
  .col-sm-4-6 {
    width: 66.66%;
  }
  .col-sm-5-6 {
    width: 83.33%;
  }
  .col-sm-6-6 {
    width: 100%;
  }
  /* 7 colonnes */
  .col-sm-1-7 {
    width: 14.28%;
  }
  .col-sm-2-7 {
    width: 28.57%;
  }
  .col-sm-3-7 {
    width: 42.86%;
  }
  .col-sm-4-7 {
    width: 57.14%;
  }
  .col-sm-5-7 {
    width: 71.43%;
  }
  .col-sm-6-7 {
    width: 85.71%;
  }
  .col-sm-7-7 {
    width: 100%;
  }
  /* 8 colonnes */
  .col-sm-1-8 {
    width: 12.5%;
  }
  .col-sm-2-8 {
    width: 25%;
  }
  .col-sm-3-8 {
    width: 37.5%;
  }
  .col-sm-4-8 {
    width: 50%;
  }
  .col-sm-5-8 {
    width: 62.5%;
  }
  .col-sm-6-8 {
    width: 75%;
  }
  .col-sm-7-8 {
    width: 87.5%;
  }
  .col-sm-8-8 {
    width: 100%;
  }
  /* 9 colonnes */
  .col-sm-1-9 {
    width: 11.11%;
  }
  .col-sm-2-9 {
    width: 22.22%;
  }
  .col-sm-3-9 {
    width: 33.33%;
  }
  .col-sm-4-9 {
    width: 44.44%;
  }
  .col-sm-5-9 {
    width: 55.55%;
  }
  .col-sm-6-9 {
    width: 66.66%;
  }
  .col-sm-7-9 {
    width: 77.77%;
  }
  .col-sm-8-9 {
    width: 88.88%;
  }
  .col-sm-9-9 {
    width: 100%;
  }
  /* 10 colonnes */
  .col-sm-1-10 {
    width: 10%;
  }
  .col-sm-2-10 {
    width: 20%;
  }
  .col-sm-3-10 {
    width: 30%;
  }
  .col-sm-4-10 {
    width: 40%;
  }
  .col-sm-5-10 {
    width: 50%;
  }
  .col-sm-6-10 {
    width: 60%;
  }
  .col-sm-7-10 {
    width: 70%;
  }
  .col-sm-8-10 {
    width: 80%;
  }
  .col-sm-9-10 {
    width: 90%;
  }
  .col-sm-10-10 {
    width: 100%;
  }
  /* 11 colonnes */
  .col-sm-1-11 {
    width: 9.09%;
  }
  .col-sm-2-11 {
    width: 18.18%;
  }
  .col-sm-3-11 {
    width: 27.27%;
  }
  .col-sm-4-11 {
    width: 36.36%;
  }
  .col-sm-5-11 {
    width: 45.45%;
  }
  .col-sm-6-11 {
    width: 54.55%;
  }
  .col-sm-7-11 {
    width: 63.64%;
  }
  .col-sm-8-11 {
    width: 72.73%;
  }
  .col-sm-9-11 {
    width: 81.82%;
  }
  .col-sm-10-11 {
    width: 90.91%;
  }
  .col-sm-11-11 {
    width: 100%;
  }
  /* 12 colonnes */
  .col-sm-1-12 {
    width: 8.33%;
  }
  .col-sm-2-12 {
    width: 16.67%;
  }
  .col-sm-3-12 {
    width: 25%;
  }
  .col-sm-4-12 {
    width: 33.33%;
  }
  .col-sm-5-12 {
    width: 41.67%;
  }
  .col-sm-6-12 {
    width: 50%;
  }
  .col-sm-7-12 {
    width: 58.33%;
  }
  .col-sm-8-12 {
    width: 66.67%;
  }
  .col-sm-9-12 {
    width: 75%;
  }
  .col-sm-10-12 {
    width: 83.33%;
  }
  .col-sm-11-12 {
    width: 91.67%;
  }
  .col-sm-12-12 {
    width: 100%;
  }
}
/*500px*/
@media only screen and (min-width: 200px) {
  [class*=col-xs] {
    float: left;
    padding-right: 8px;
    padding-left: 8px;
  }
  [class*=col-xs]:first-of-type {
    padding-left: 0;
  }
  [class*=col-xs]:last-of-type {
    padding-right: 0;
  }
  .col-xs-1-2 {
    width: 50%;
  }
  .col-xs-2-2 {
    width: 100%;
  }
  /* 3 colonnes */
  .col-xs-1-3 {
    width: 33.33%;
  }
  .col-xs-2-3 {
    width: 66.66%;
  }
  .col-xs-3-3 {
    width: 100%;
  }
  /* 4 colonnes */
  .col-xs-1-4 {
    width: 25%;
  }
  .col-xs-2-4 {
    width: 50%;
  }
  .col-xs-3-4 {
    width: 75%;
  }
  .col-xs-4-4 {
    width: 100%;
  }
  /* 5 colonnes */
  .col-xs-1-5 {
    width: 20%;
  }
  .col-xs-2-5 {
    width: 40%;
  }
  .col-xs-3-5 {
    width: 60%;
  }
  .col-xs-4-5 {
    width: 80%;
  }
  .col-xs-5-5 {
    width: 100%;
  }
  /* 6 colonnes */
  .col-xs-1-6 {
    width: 16.66%;
  }
  .col-xs-2-6 {
    width: 33.33%;
  }
  .col-xs-3-6 {
    width: 50%;
  }
  .col-xs-4-6 {
    width: 66.66%;
  }
  .col-xs-5-6 {
    width: 83.33%;
  }
  .col-xs-6-6 {
    width: 100%;
  }
  /* 7 colonnes */
  .col-xs-1-7 {
    width: 14.28%;
  }
  .col-xs-2-7 {
    width: 28.57%;
  }
  .col-xs-3-7 {
    width: 42.86%;
  }
  .col-xs-4-7 {
    width: 57.14%;
  }
  .col-xs-5-7 {
    width: 71.43%;
  }
  .col-xs-6-7 {
    width: 85.71%;
  }
  .col-xs-7-7 {
    width: 100%;
  }
  /* 8 colonnes */
  .col-xs-1-8 {
    width: 12.5%;
  }
  .col-xs-2-8 {
    width: 25%;
  }
  .col-xs-3-8 {
    width: 37.5%;
  }
  .col-xs-4-8 {
    width: 50%;
  }
  .col-xs-5-8 {
    width: 62.5%;
  }
  .col-xs-6-8 {
    width: 75%;
  }
  .col-xs-7-8 {
    width: 87.5%;
  }
  .col-xs-8-8 {
    width: 100%;
  }
  /* 9 colonnes */
  .col-xs-1-9 {
    width: 11.11%;
  }
  .col-xs-2-9 {
    width: 22.22%;
  }
  .col-xs-3-9 {
    width: 33.33%;
  }
  .col-xs-4-9 {
    width: 44.44%;
  }
  .col-xs-5-9 {
    width: 55.55%;
  }
  .col-xs-6-9 {
    width: 66.66%;
  }
  .col-xs-7-9 {
    width: 77.77%;
  }
  .col-xs-8-9 {
    width: 88.88%;
  }
  .col-xs-9-9 {
    width: 100%;
  }
  /* 10 colonnes */
  .col-xs-1-10 {
    width: 10%;
  }
  .col-xs-2-10 {
    width: 20%;
  }
  .col-xs-3-10 {
    width: 30%;
  }
  .col-xs-4-10 {
    width: 40%;
  }
  .col-xs-5-10 {
    width: 50%;
  }
  .col-xs-6-10 {
    width: 60%;
  }
  .col-xs-7-10 {
    width: 70%;
  }
  .col-xs-8-10 {
    width: 80%;
  }
  .col-xs-9-10 {
    width: 90%;
  }
  .col-xs-10-10 {
    width: 100%;
  }
  /* 11 colonnes */
  .col-xs-1-11 {
    width: 9.09%;
  }
  .col-xs-2-11 {
    width: 18.18%;
  }
  .col-xs-3-11 {
    width: 27.27%;
  }
  .col-xs-4-11 {
    width: 36.36%;
  }
  .col-xs-5-11 {
    width: 45.45%;
  }
  .col-xs-6-11 {
    width: 54.55%;
  }
  .col-xs-7-11 {
    width: 63.64%;
  }
  .col-xs-8-11 {
    width: 72.73%;
  }
  .col-xs-9-11 {
    width: 81.82%;
  }
  .col-xs-10-11 {
    width: 90.91%;
  }
  .col-xs-11-11 {
    width: 100%;
  }
  /* 12 colonnes */
  .col-xs-1-12 {
    width: 8.33%;
  }
  .col-xs-2-12 {
    width: 16.67%;
  }
  .col-xs-3-12 {
    width: 25%;
  }
  .col-xs-4-12 {
    width: 33.33%;
  }
  .col-xs-5-12 {
    width: 41.67%;
  }
  .col-xs-6-12 {
    width: 50%;
  }
  .col-xs-7-12 {
    width: 58.33%;
  }
  .col-xs-8-12 {
    width: 66.67%;
  }
  .col-xs-9-12 {
    width: 75%;
  }
  .col-xs-10-12 {
    width: 83.33%;
  }
  .col-xs-11-12 {
    width: 91.67%;
  }
  .col-xs-12-12 {
    width: 100%;
  }
}
/*768px breakpoint*/
@media only screen and (min-width: 48em) {
  [class*=col-] {
    float: left;
    padding-right: 10px;
  }
  .col-med-1-2 {
    width: 50%;
  }
  .col-med-2-2 {
    width: 100%;
  }
  /* 3 colonnes */
  .col-med-1-3 {
    width: 33.33%;
  }
  .col-med-2-3 {
    width: 66.66%;
  }
  .col-med-3-3 {
    width: 100%;
  }
  /* 4 colonnes */
  .col-med-1-4 {
    width: 25%;
  }
  .col-med-2-4 {
    width: 50%;
  }
  .col-med-3-4 {
    width: 75%;
  }
  .col-med-4-4 {
    width: 100%;
  }
  /* 5 colonnes */
  .col-med-1-5 {
    width: 20%;
  }
  .col-med-2-5 {
    width: 40%;
  }
  .col-med-3-5 {
    width: 60%;
  }
  .col-med-4-5 {
    width: 80%;
  }
  .col-med-5-5 {
    width: 100%;
  }
  /* 6 colonnes */
  .col-med-1-6 {
    width: 16.66%;
  }
  .col-med-2-6 {
    width: 33.33%;
  }
  .col-med-3-6 {
    width: 50%;
  }
  .col-med-4-6 {
    width: 66.66%;
  }
  .col-med-5-6 {
    width: 83.33%;
  }
  .col-med-6-6 {
    width: 100%;
  }
  /* 7 colonnes */
  .col-med-1-7 {
    width: 14.28%;
  }
  .col-med-2-7 {
    width: 28.57%;
  }
  .col-med-3-7 {
    width: 42.86%;
  }
  .col-med-4-7 {
    width: 57.14%;
  }
  .col-med-5-7 {
    width: 71.43%;
  }
  .col-med-6-7 {
    width: 85.71%;
  }
  .col-med-7-7 {
    width: 100%;
  }
  /* 8 colonnes */
  .col-med-1-8 {
    width: 12.5%;
  }
  .col-med-2-8 {
    width: 25%;
  }
  .col-med-3-8 {
    width: 37.5%;
  }
  .col-med-4-8 {
    width: 50%;
  }
  .col-med-5-8 {
    width: 62.5%;
  }
  .col-med-6-8 {
    width: 75%;
  }
  .col-med-7-8 {
    width: 87.5%;
  }
  .col-med-8-8 {
    width: 100%;
  }
  /* 9 colonnes */
  .col-med-1-9 {
    width: 11.11%;
  }
  .col-med-2-9 {
    width: 22.22%;
  }
  .col-med-3-9 {
    width: 33.33%;
  }
  .col-med-4-9 {
    width: 44.44%;
  }
  .col-med-5-9 {
    width: 55.55%;
  }
  .col-med-6-9 {
    width: 66.66%;
  }
  .col-med-7-9 {
    width: 77.77%;
  }
  .col-med-8-9 {
    width: 88.88%;
  }
  .col-med-9-9 {
    width: 100%;
  }
  /* 10 colonnes */
  .col-med-1-10 {
    width: 10%;
  }
  .col-med-2-10 {
    width: 20%;
  }
  .col-med-3-10 {
    width: 30%;
  }
  .col-med-4-10 {
    width: 40%;
  }
  .col-med-5-10 {
    width: 50%;
  }
  .col-med-6-10 {
    width: 60%;
  }
  .col-med-7-10 {
    width: 70%;
  }
  .col-med-8-10 {
    width: 80%;
  }
  .col-med-9-10 {
    width: 90%;
  }
  .col-med-10-10 {
    width: 100%;
  }
  /* 11 colonnes */
  .col-med-1-11 {
    width: 9.09%;
  }
  .col-med-2-11 {
    width: 18.18%;
  }
  .col-med-3-11 {
    width: 27.27%;
  }
  .col-med-4-11 {
    width: 36.36%;
  }
  .col-med-5-11 {
    width: 45.45%;
  }
  .col-med-6-11 {
    width: 54.55%;
  }
  .col-med-7-11 {
    width: 63.64%;
  }
  .col-med-8-11 {
    width: 72.73%;
  }
  .col-med-9-11 {
    width: 81.82%;
  }
  .col-med-10-11 {
    width: 90.91%;
  }
  .col-med-11-11 {
    width: 100%;
  }
  /* 12 colonnes */
  .col-med-1-12 {
    width: 8.33%;
  }
  .col-med-2-12 {
    width: 16.67%;
  }
  .col-med-3-12 {
    width: 25%;
  }
  .col-med-4-12 {
    width: 33.33%;
  }
  .col-med-5-12 {
    width: 41.67%;
  }
  .col-med-6-12 {
    width: 50%;
  }
  .col-med-7-12 {
    width: 58.33%;
  }
  .col-med-8-12 {
    width: 66.67%;
  }
  .col-med-9-12 {
    width: 75%;
  }
  .col-med-10-12 {
    width: 83.33%;
  }
  .col-med-11-12 {
    width: 91.67%;
  }
  .col-med-12-12 {
    width: 100%;
  }
}
/*1140px breakpoint*/
@media only screen and (min-width: 71.25em) {
  #main-content {
    padding-left: 250px;
    padding-right: 10px;
  }
  .col-lg-1-2 {
    width: 50%;
  }
  .col-lg-2-2 {
    width: 100%;
  }
  /* 3 colonnes */
  .col-lg-1-3 {
    width: 33.33%;
  }
  .col-lg-2-3 {
    width: 66.66%;
  }
  .col-lg-3-3 {
    width: 100%;
  }
  /* 4 colonnes */
  .col-lg-1-4 {
    width: 25%;
  }
  .col-lg-2-4 {
    width: 50%;
  }
  .col-lg-3-4 {
    width: 75%;
  }
  .col-lg-4-4 {
    width: 100%;
  }
  /* 5 colonnes */
  .col-lg-1-5 {
    width: 20%;
  }
  .col-lg-2-5 {
    width: 40%;
  }
  .col-lg-3-5 {
    width: 60%;
  }
  .col-lg-4-5 {
    width: 80%;
  }
  .col-lg-5-5 {
    width: 100%;
  }
  /* 6 colonnes */
  .col-lg-1-6 {
    width: 16.66%;
  }
  .col-lg-2-6 {
    width: 33.33%;
  }
  .col-lg-3-6 {
    width: 50%;
  }
  .col-lg-4-6 {
    width: 66.66%;
  }
  .col-lg-5-6 {
    width: 83.33%;
  }
  .col-lg-6-6 {
    width: 100%;
  }
  /* 7 colonnes */
  .col-lg-1-7 {
    width: 14.28%;
  }
  .col-lg-2-7 {
    width: 28.57%;
  }
  .col-lg-3-7 {
    width: 42.86%;
  }
  .col-lg-4-7 {
    width: 57.14%;
  }
  .col-lg-5-7 {
    width: 71.43%;
  }
  .col-lg-6-7 {
    width: 85.71%;
  }
  .col-lg-7-7 {
    width: 100%;
  }
  /* 8 colonnes */
  .col-lg-1-8 {
    width: 12.5%;
  }
  .col-lg-2-8 {
    width: 25%;
  }
  .col-lg-3-8 {
    width: 37.5%;
  }
  .col-lg-4-8 {
    width: 50%;
  }
  .col-lg-5-8 {
    width: 62.5%;
  }
  .col-lg-6-8 {
    width: 75%;
  }
  .col-lg-7-8 {
    width: 87.5%;
  }
  .col-lg-8-8 {
    width: 100%;
  }
  /* 9 colonnes */
  .col-lg-1-9 {
    width: 11.11%;
  }
  .col-lg-2-9 {
    width: 22.22%;
  }
  .col-lg-3-9 {
    width: 33.33%;
  }
  .col-lg-4-9 {
    width: 44.44%;
  }
  .col-lg-5-9 {
    width: 55.55%;
  }
  .col-lg-6-9 {
    width: 66.66%;
  }
  .col-lg-7-9 {
    width: 77.77%;
  }
  .col-lg-8-9 {
    width: 88.88%;
  }
  .col-lg-9-9 {
    width: 100%;
  }
  /* 10 colonnes */
  .col-lg-1-10 {
    width: 10%;
  }
  .col-lg-2-10 {
    width: 20%;
  }
  .col-lg-3-10 {
    width: 30%;
  }
  .col-lg-4-10 {
    width: 40%;
  }
  .col-lg-5-10 {
    width: 50%;
  }
  .col-lg-6-10 {
    width: 60%;
  }
  .col-lg-7-10 {
    width: 70%;
  }
  .col-lg-8-10 {
    width: 80%;
  }
  .col-lg-9-10 {
    width: 90%;
  }
  .col-lg-10-10 {
    width: 100%;
  }
  /* 11 colonnes */
  .col-lg-1-11 {
    width: 9.09%;
  }
  .col-lg-2-11 {
    width: 18.18%;
  }
  .col-lg-3-11 {
    width: 27.27%;
  }
  .col-lg-4-11 {
    width: 36.36%;
  }
  .col-lg-5-11 {
    width: 45.45%;
  }
  .col-lg-6-11 {
    width: 54.55%;
  }
  .col-lg-7-11 {
    width: 63.64%;
  }
  .col-lg-8-11 {
    width: 72.73%;
  }
  .col-lg-9-11 {
    width: 81.82%;
  }
  .col-lg-10-11 {
    width: 90.91%;
  }
  .col-lg-11-11 {
    width: 100%;
  }
  /* 12 colonnes */
  .col-lg-1-12 {
    width: 8.33%;
  }
  .col-lg-2-12 {
    width: 16.67%;
  }
  .col-lg-3-12 {
    width: 25%;
  }
  .col-lg-4-12 {
    width: 33.33%;
  }
  .col-lg-5-12 {
    width: 41.67%;
  }
  .col-lg-6-12 {
    width: 50%;
  }
  .col-lg-7-12 {
    width: 58.33%;
  }
  .col-lg-8-12 {
    width: 66.67%;
  }
  .col-lg-9-12 {
    width: 75%;
  }
  .col-lg-10-12 {
    width: 83.33%;
  }
  .col-lg-11-12 {
    width: 91.67%;
  }
  .col-lg-12-12 {
    width: 100%;
  }
}
/* Utility classes to hide content based on window size */
[class*=show-]:not(.show-modal) {
  display: none;
}

@media only screen and (min-width: 30em) {
  .show-480px {
    display: block;
    display: initial;
  }
  .hide-480px {
    display: none;
  }
}
@media only screen and (min-width: 48em) {
  .show-768px {
    display: block;
    display: initial;
  }
  .hide-768px {
    display: none;
  }
}
@media only screen and (min-width: 71.25em) {
  .show-1140px {
    display: block;
    display: initial;
  }
  .hide-1140px {
    display: none;
  }
}
.container-commun {
  position: relative;
  width: 100%;
  padding: 0 16px;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}
@media (min-width: 48em) {
  .container-commun {
    padding: 0 32px;
  }
}
.container-commun .row {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
}

.fullscreen-bg_video video {
  display: none;
}
@media screen and (min-width: 1140px) {
  .fullscreen-bg_video {
    position: relative;
    background: none !important;
  }
  .fullscreen-bg_video video {
    display: block;
    top: 0;
    left: 0;
    z-index: 0;
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    position: absolute;
    z-index: -1;
  }
}

.grille-animation-parallax {
  position: relative;
}
@media screen and (min-width: 1140px) {
  .grille-animation-parallax {
    background-image: none !important;
  }
}
.grille-animation-parallax .commun-container-parallax {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 0;
  display: none;
}
@media screen and (min-width: 1140px) {
  .grille-animation-parallax .commun-container-parallax {
    display: block;
  }
}